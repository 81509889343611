import React from 'react';
import styles from './home.module.scss';
import Section from '@/components/Section';
import {
    ImageWrapper,
    LightSection,
    LightSectionButtonContainer,
    LightSectionGridWrapper,
    LightSectionTitle,
    LightSubSection,
} from '@/components/Shared/ContentSection';
import Image from 'next/image';
import analyticsImage from '#/images/home/analytics.png';
import CheckIconItem from '@/components/IconList/CheckIconItem';
import { getRefLink } from '@/lib/helpers';
import { Button } from '@/components/Buttons';
import Link from '@/components/Link';
import { ROUTES } from '@/lib/constants.mjs';
import { Trans, useTranslation } from 'next-i18next';

const AnalyticsSection = () => {
    const { t } = useTranslation(['home', 'common']);
    const features = [
        t('home:analytics_section.feature_1' as any),
        t('home:analytics_section.feature_2' as any),
        t('home:analytics_section.feature_3' as any),
        t('home:analytics_section.feature_4' as any),
    ];

    return (
        <Section>
            <LightSection marginBottom marginTop>
                <LightSubSection>
                    <LightSectionTitle className={`mobile ${styles.sectionTitle}`}>
                        {t('home:analytics_section.title' as any)}
                    </LightSectionTitle>
                    <ImageWrapper>
                        <Image
                            src={analyticsImage}
                            alt="In-Depth Analytics"
                            quality={100}
                            className="section-image"
                        />
                    </ImageWrapper>
                </LightSubSection>
                <LightSubSection gap="0">
                    <LightSectionTitle className={`desktop`}>
                        {t('home:analytics_section.title' as any)}
                    </LightSectionTitle>
                    <p className="body">
                        <Trans
                            i18nKey="home:analytics_section.description"
                            components={{ b: <b /> }}
                            defaults={
                                'Access to industry leading trade analytics. Perfect for day / intra-day / and swing traders. <b>Monitor</b> trading progress and <b>analyse performance</b> over every historic interaction with each asset pair in <b>real time</b>.'
                            }
                        />
                    </p>
                    <LightSectionGridWrapper>
                        <div className={`grid ${styles.analyticsGrid}`}>
                            {features.map((feature) => (
                                <CheckIconItem key={feature} light={false}>
                                    {feature}
                                </CheckIconItem>
                            ))}
                        </div>
                    </LightSectionGridWrapper>
                    <LightSectionButtonContainer>
                        <Button
                            className={'lightSectionButton'}
                            to={getRefLink()}
                            size={'large'}
                            mobileSize={'full'}
                            color={'primary'}
                            blank={false}
                        >
                            {t('common:get_started_button_label' as any)}
                        </Button>
                        <Link
                            color="primary"
                            label={t('home:analytics_section.section_image_alt' as any) as string}
                            icon
                            to={ROUTES.TRACKING_ANALYTICS}
                        />
                    </LightSectionButtonContainer>
                </LightSubSection>
            </LightSection>
        </Section>
    );
};

export default AnalyticsSection;
