import React from 'react';
import Section from '@/components/Section';
import styles from './home.module.scss';
import { getRefLink } from '@/lib/helpers';
import { Button } from '@/components/Buttons';
import PersonIcon from '#/images/icons/person.svg';
import LinkIcon from '#/images/icons/link.svg';
import EditIcon from '#/images/icons/edit.svg';
import { useTranslation } from 'next-i18next';

const GetStartedSection = () => {
    const { t } = useTranslation('home');
    return (
        <Section>
            <div className={`${styles.getStartedSection} marginBottomDesktop`}>
                <div className={styles.getStartedTextWrapper}>
                    <p className="as-h3 fw700">{t('home:get_started_section.title' as any)}</p>
                    {/*<p className="as-h2 fw700 mobile">Get Started In Just A Few Minutes</p>*/}
                    <p style={{ marginTop: '12px' }}>
                        {t('home:get_started_section.subtitle' as any)}
                    </p>
                </div>
                <div className={`${styles.buttonContainer} mobile`}>
                    <Button size={'full'} color={'primary'} to={getRefLink()} blank={false}>
                        {t('common:register_button_label' as any)}
                    </Button>
                </div>
                <div className={styles.getStartedIconWrapper}>
                    <div className={styles.iconContainer}>
                        <PersonIcon className={styles.icon} />
                        <p className={styles.iconLabel}>
                            {t('home:get_started_section.step_1' as any)}
                        </p>
                    </div>
                    <div className={styles.divider} />
                    <div className={styles.iconContainer}>
                        <LinkIcon className={styles.icon} />
                        <p className={styles.iconLabel}>
                            {t('home:get_started_section.step_2' as any)}
                        </p>
                    </div>
                    <div className={styles.divider} />
                    <div className={styles.iconContainer}>
                        <EditIcon className={styles.icon} />
                        <p className={styles.iconLabel}>
                            {t('home:get_started_section.step_3' as any)}
                        </p>
                    </div>
                </div>
                <div className={`${styles.buttonContainer} desktop`}>
                    <Button size={'large'} color={'primary'} to={getRefLink()} blank={false}>
                        {t('common:register_button_label' as any)}
                    </Button>
                </div>
            </div>
        </Section>
    );
};

export default GetStartedSection;
