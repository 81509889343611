import React from 'react';
import Home from '@/pageComponents/Home';
import { QA, Tweet } from '@/types/generalTypes';
import awsService from '@/server/services/aws/awsService';
import { fetchStrapi } from '@/server/services/strapi/apiService';
import { NextPage } from 'next';
import { compareDesc } from 'date-fns';
import { parseMarkdownText } from '@/lib/parseMarkdown';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import nextI18NextConfig from '@/../next-i18next.config';

interface HomePageProps {
    tweets: {
        top: Tweet[];
        home: Tweet[];
    };
    unlockedQA: QA[];
    contactQA: QA[];

    [key: string]: any;
}

const HomePage: NextPage<HomePageProps> = ({
    tweets,
    draftMode,
    unlockedQA = [],
    contactQA = [],
}) => {
    return (
        <Home tweets={tweets} draftMode={draftMode} unlockedQA={unlockedQA} contactQA={contactQA} />
    );
};

export async function getStaticProps(context: any) {
    const translations = await serverSideTranslations(
        context.locale || 'en',
        ['common', 'home', 'cookies'],
        nextI18NextConfig,
    );
    // Prevent invoke lambda in dev
    if (process.env.NODE_ENV === 'development') {
        return {
            props: {
                ...translations,
                tweets: {
                    top: [],
                    home: [],
                },
            },
        };
    }

    // get tweets
    const params = {
        sort: ['publishedAt:desc'],
        populate: '*',
        fields: ['tweetId'],
        publicationState: 'live',
    };
    const data = await fetchStrapi<any>('/testimonials', params);

    const ids = data?.map((item) => ({
        id: item.attributes.tweetId,
        category: item.attributes.testimonial_category.data.attributes.name,
    }));

    const home = ids?.filter((item) => item.category === 'home').map((item) => item.id) || [];
    const top = ids?.filter((item) => item.category === 'topSection').map((item) => item.id) || [];

    const homeTweetsResult = await awsService.invokeLambda({
        body: { tweetIds: home },
    });

    let homeParsedTweets = [];

    if (homeTweetsResult?.invokeResult) {
        homeParsedTweets = homeTweetsResult?.invokeResult
            .filter((item: any) => item !== null)
            .map((tweet: any) => ({ ...tweet, section: 'home' }));

        homeParsedTweets.sort((a: Tweet, b: Tweet) =>
            compareDesc(new Date(a.created), new Date(b.created)),
        );
    }

    const topTweetsResult = await awsService.invokeLambda({
        body: { tweetIds: top },
    });

    let topSectionParsedTweets = [];

    if (topTweetsResult?.invokeResult) {
        topSectionParsedTweets = topTweetsResult?.invokeResult
            .filter((item: any) => item !== null)
            .map((tweet: any) => ({ ...tweet, section: 'topSection' }));

        topSectionParsedTweets.sort((a: Tweet, b: Tweet) =>
            compareDesc(new Date(a.created), new Date(b.created)),
        );
    }
    const qaParams = {
        populate: '*',
        locale: context.locale,
    };
    const qa = await fetchStrapi<any>('/faqs/', qaParams);
    const postfix = context.locale === 'en' ? '' : `--${context.locale}`;

    const unlockedEntry = qa?.find((entry) => entry.attributes.faqid === `unlocked${postfix}`);

    const unlockedContent = unlockedEntry
        ? unlockedEntry?.attributes?.qa?.map((el: any) => ({
              questionName: el.question || '',
              acceptedAnswerText: el.answer || '',
          }))
        : [];

    const contactEntry = qa?.find((entry) => entry.attributes.faqid === `contact-us${postfix}`);
    const contactContent = contactEntry
        ? contactEntry?.attributes?.qa?.map((el: any) => ({
              questionName: el.question || '',
              acceptedAnswerText: el.answer || '',
          }))
        : [];

    const unlockedQA = await Promise.all(
        unlockedContent.map(async (item: any) => ({
            ...item,
            acceptedAnswerText: (await parseMarkdownText(item.acceptedAnswerText)).toString(),
        })),
    );

    const contactQA = await Promise.all(
        contactContent.map(async (item: any) => ({
            ...item,
            acceptedAnswerText: (await parseMarkdownText(item.acceptedAnswerText)).toString(),
        })),
    );
    return {
        props: {
            ...translations,
            unlockedQA,
            contactQA,
            tweets: {
                top: topSectionParsedTweets as Tweet[],
                home: homeParsedTweets as Tweet[],
            },
            draftMode: Boolean(context?.draftMode),
        },
    };
}

export default HomePage;
