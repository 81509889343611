import React, { useEffect, useState } from 'react';
import Section from '@/components/Section';
import { MainSection, MainSubSection } from '@/components/Shared';
import { Button } from '@/components/Buttons';
import { getCookieByName, getRefLink } from '@/lib/helpers';
import IconListItem from '@/components/IconList/IconListItem';
import styles from './home.module.scss';
import Image from 'next/image';
import topHero from '#/images/home/top_hero.png';
import ArrowIcon from '#/images/icons/arrow-up-down.svg';
import BalanceIcon from '#/images/icons/balance.svg';
import ClockIcon from '#/images/icons/clock.svg';
import UserIcon from '#/images/icons/user.svg';
import { DASHBOARD_URL } from '@/lib/constants.mjs';
import Carousel from '@/components/Carousel';
import { Tweet } from '@/types/generalTypes';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';

interface TopSectionProps {
    tweets: Tweet[];
}

const TopSection: React.FC<TopSectionProps> = ({ tweets = [] }) => {
    const [isLoggedInDashboard, setLoggedInDashboard] = useState(false);
    const { t } = useTranslation(['home', 'common']);
    const { locale } = useRouter();
    useEffect(() => {
        if (document) {
            if (process.env.NEXT_PUBLIC_ENVR === getCookieByName('loggedEnv'))
                setLoggedInDashboard(getCookieByName('isAuth') === 'true');
        }
    }, []);
    const list = [
        {
            icon: <ArrowIcon />,
            title: t('home:top_section.feature_1_title' as any),
            description: t('home:top_section.feature_1_subtitle' as any),
        },
        {
            icon: <UserIcon />,
            title: t('home:top_section.feature_2_title' as any),
            description: t('home:top_section.feature_2_subtitle' as any),
        },
        {
            icon: <BalanceIcon />,
            title: t('home:top_section.feature_3_title' as any),
            description: t('home:top_section.feature_3_subtitle' as any),
        },
        {
            icon: <ClockIcon />,
            title: t('home:top_section.feature_4_title' as any),
            description: t('home:top_section.feature_4_subtitle' as any),
        },
    ];

    return (
        <Section
            padding={'0'}
            withHeader
            className={`fillBgSkewBottom ${styles.homeTopSection}`}
            innerClassName={styles.homeInnerTopSection}
        >
            <MainSection marginTop removeMarginTopOnMobile>
                <MainSubSection gap="24px">
                    <h1
                        className={`${styles.titleMargin} ${
                            locale === 'ru' || locale === 'uk' ? 'as-h2' : 'as-h1'
                        } ${styles.title}`}
                    >
                        {t('home:top_section.title' as any)}
                    </h1>
                    <p className={`subheader subheader-light`}>
                        {t('home:top_section.subtitle' as any)}
                    </p>
                    <Button
                        className={`desktop ${styles.homeTopSectionButton}`}
                        size={'large'}
                        color={'light'}
                        to={getRefLink()}
                        blank={false}
                    >
                        {t('common:register_button_label' as any)}
                    </Button>
                    <div className={`${styles.mobileButtons} mobile`}>
                        {isLoggedInDashboard ? (
                            <Button size={'full'} color={'navy'} to={getRefLink()} blank={false}>
                                Return to dashboard
                            </Button>
                        ) : (
                            <>
                                <Button
                                    size={'full'}
                                    color={'light'}
                                    asAnchor
                                    to={`${DASHBOARD_URL}/login`}
                                >
                                    {t('common:login_button_label' as any)}
                                </Button>
                                <Button
                                    size={'full'}
                                    color={'navy'}
                                    to={getRefLink()}
                                    blank={false}
                                >
                                    {t('common:sign_up_button_label' as any)}
                                </Button>
                            </>
                        )}
                    </div>
                </MainSubSection>
                <MainSubSection>
                    <Image
                        src={topHero}
                        alt={t('home:top_section.section_image_alt' as any) as string}
                        priority
                        className="hero-image"
                        placeholder="blur"
                    />
                </MainSubSection>
            </MainSection>
            <MainSection marginBottom removeMarginBottomOnMobile>
                <MainSubSection className={styles.carouselSubsection}>
                    <div className={styles.carouselWrapper}>
                        <Carousel data={tweets} />
                    </div>
                </MainSubSection>
                <MainSubSection>
                    <div className={`grid ${styles.homeTopSectionList}`}>
                        {list.map(({ icon, title, description }) => (
                            <IconListItem
                                icon={icon}
                                title={title as string}
                                description={description as string}
                                key={title}
                                textWrapperStyles={{ gap: 0 }}
                                wrapperStyles={{ gap: '16px' }}
                                titleStyle={{ fontSize: 'px' }}
                            />
                        ))}
                    </div>
                </MainSubSection>
            </MainSection>
        </Section>
    );
};

export default TopSection;
