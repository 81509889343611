import React, { useState } from 'react';
import styles from './caorusel.module.scss';
import LeftArrow from '#/images/svg/arrow_left.svg';
import RightArrow from '#/images/svg/arrow_right.svg';
import { Tweet } from '@/types/generalTypes';
import { CarouselProvider, Slide, Slider } from 'pure-react-carousel';

interface CarouselProps {
    data: Tweet[];
}

const Carousel: React.FC<CarouselProps> = ({ data = [] }) => {
    const [currentSlide, setCurrentSlide] = useState(0);

    const onChangeNext = () => {
        setCurrentSlide(currentSlide + 1 === data.length ? 0 : currentSlide + 1);
    };
    const onChangePrev = () => {
        setCurrentSlide(currentSlide - 1 < 0 ? data.length - 1 : currentSlide - 1);
    };

    const onTweetClick = (link: string) => {
        if (typeof window !== 'undefined') {
            window.open(link, '_blank', 'noreferrer noopener');
        }
    };
    return (
        <div className={styles.carouselWrapper}>
            <div className={styles.carousel}>
                <CarouselProvider
                    currentSlide={currentSlide}
                    naturalSlideWidth={100}
                    naturalSlideHeight={60}
                    totalSlides={data.length}
                    orientation={'horizontal'}
                    step={1}
                    lockOnWindowScroll
                    className={styles.carProvider}
                    dragEnabled
                >
                    <Slider className={styles.slider}>
                        {data.map((tw, index) => (
                            <Slide index={index} key={tw.id}>
                                <a
                                    className={styles.twitWrapper}
                                    onClick={() => {
                                        onTweetClick(tw.link);
                                    }}
                                    key={tw.id}
                                >
                                    <p className={`label ${styles.text}`}>{tw.text}</p>
                                    <div className={styles.twitAuthorWrapper}>
                                        {tw.profileImage && (
                                            <img
                                                src={tw.profileImage}
                                                alt={tw.name}
                                                className={styles.authorImage}
                                                loading="lazy"
                                            />
                                        )}
                                        <div className={styles.authorText}>
                                            <p className={'as-h4'}>{tw.name}</p>
                                            <p className={'small'}>{tw.username}</p>
                                        </div>
                                    </div>
                                </a>
                            </Slide>
                        ))}
                    </Slider>
                </CarouselProvider>
            </div>
            <div className={styles.arrows}>
                <LeftArrow className={styles.arrow} onClick={onChangePrev} />
                <RightArrow className={styles.arrow} onClick={onChangeNext} />
            </div>
        </div>
    );
};

export default Carousel;
