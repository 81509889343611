import React from 'react';
import styles from './home.module.scss';
import Section from '@/components/Section';
import Image from 'next/image';
import binance from '#/images/exchanges/2x_binance.png';
import bybit from '#/images/exchanges/2x_bybit.png';
import deribit from '#/images/exchanges/2x_deribit.png';
import woo from '#/images/exchanges/2x_woo.png';
import bitget from '#/images/exchanges/2x_bitget.png';
import phemex from '#/images/exchanges/2x_phemex.png';
import { useTranslation } from 'next-i18next';

const ExchangesSection = () => {
    const { t } = useTranslation('home', { keyPrefix: 'exchanges_section' });
    return (
        <Section>
            <div className={styles.exchangeWrapper}>
                <div className={styles.exchangeTextWrapper}>
                    <p className={`as-h2 desktop`}>{t('title' as any)}</p>
                    <p className={'as-h3 mobile'}>{t('title' as any)}</p>
                    <p className="body">{t('subtitle' as any)}</p>
                </div>
                <div className={styles.exchangeLogosWrapper}>
                    <Image
                        src={binance}
                        alt="Binance"
                        quality={100}
                        className={styles.exchangeLogoImg}
                    />
                    <Image
                        src={bybit}
                        alt="Bybit"
                        quality={100}
                        className={styles.exchangeLogoImg}
                    />
                    <Image
                        src={deribit}
                        alt="Deribit"
                        quality={100}
                        className={styles.exchangeLogoImg}
                    />
                    <Image src={woo} alt="Woo X" quality={100} className={styles.exchangeLogoImg} />
                    <Image
                        src={bitget}
                        alt="Woo X"
                        quality={100}
                        className={styles.exchangeLogoImg}
                    />
                    <Image
                        src={phemex}
                        alt="Woo X"
                        quality={100}
                        className={styles.exchangeLogoImg}
                    />
                </div>
            </div>
        </Section>
    );
};

export default ExchangesSection;
